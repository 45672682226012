var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("eSocial - Empresas"),
      ]),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pl-0 pr-0" },
            [
              _c("TableSearch", {
                attrs: { label: "Pesquisar...", delay: 0 },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.estabelecimentosList,
              "items-per-page": 15,
              search: _vm.search,
              loading: _vm.myloadingvariable,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
            },
            on: { "click:row": _vm.openDialoEstabInform },
            scopedSlots: _vm._u([
              {
                key: "item.nrInscIdeempregador",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("cnpj")(item.nrInscIdeempregador)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600px" },
              model: {
                value: _vm.dialoEstabInform,
                callback: function ($$v) {
                  _vm.dialoEstabInform = $$v
                },
                expression: "dialoEstabInform",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v("Informações evtTabEstab"),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        attrs: {
                          headers: _vm.headerEvtTabEstab,
                          items: _vm.tabEstabs,
                          "footer-props": {
                            "items-per-page-options": [10, 25, 50],
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            text: "",
                            id: "IdBtnClose",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialoEstabInform = false
                            },
                          },
                        },
                        [_vm._v(" Fechar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
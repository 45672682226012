<template><TableEmpresas /></template>

<script>
import { mapActions } from 'vuex';

import TableEmpresas from './components/TableEmpresas.vue';

export default {
  name: 'Empresas',

  components: { TableEmpresas },

  data() {
    return {};
  },

  created() {
    // this.init();
  },

  methods: {
    ...mapActions('esocial', ['carregarGrid']),
    init() {
      this.carregarGrid();
    },
  },
};
</script>

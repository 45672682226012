<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">eSocial - Empresas</h2>

    <v-card>  
      <v-card-title class="pl-0 pr-0">
        <TableSearch
          label="Pesquisar..."
          v-model="search"
          :delay="0"
        ></TableSearch>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="estabelecimentosList"
        :items-per-page="15"
        :search="search"
        :loading="myloadingvariable"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        @click:row="openDialoEstabInform"
      >
        <template v-slot:item.nrInscIdeempregador="{ item }">
          {{ item.nrInscIdeempregador | cnpj }}
        </template>
      </v-data-table>
    </v-card>

    <!--Modal de informacoes de evtTabEstab -->
    <v-row justify="center">
      <v-dialog v-model="dialoEstabInform" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Informações evtTabEstab</span>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headerEvtTabEstab"
              :items="tabEstabs"
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
              }"
            ></v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialoEstabInform = false" id="IdBtnClose"> Fechar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-main>
</template>

<script>
import eSocialService from '@/services/eSocialService.js'; //API evt tab
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Estabelecimentos',

  components: {
    TableSearch: () => import('../../commons/TableSearch.vue'),
  },

  data() {
    return {
      // myloadingvariable: true,
      dialoEstabInform: false,
      search: '',
      headerEvtTabEstab: [
        { value: 'cnaePrepDadosestab', text: 'CNAE Prep Dados estabelecimento' },
        { value: 'aliqRatAliqGilrat', text: 'Aliquota Rat Aliq Gilrat' },
        { value: 'fapAliqGilrat', text: 'Fap Aliquota Gilrat' },
        { value: 'aliqRatAjustAliqGilrat', text: 'Aliq Rat Ajustada Aliquota Gilrat' },
      ],

      headers: [
        // { text: 'Razão Social', align: 'start', value: 'nmRazaoInfoeSocial' },
        { text: 'CNPJ', value: 'nrInscIdeempregador' },
      ],

      tabEstabs: [],
    };
  },

  computed: {
    ...mapGetters({
      myloadingvariable: 'esocial/esocialMain/getLoadingForEmpresa',
      estabelecimentosList: 'esocial/esocialMain/getEstabelecimentos',
    }),
  },
  created() {
    this.carregarGrid();
  },

  methods: {
    ...mapActions('esocial/esocialMain/', ['carregarGrid']),

    async openDialoEstabInform() {
      let estabs = await eSocialService.evttabestab.get();

      this.tabEstabs = estabs.data;
      this.dialoEstabInform = true;
    },
  },
};
</script>
